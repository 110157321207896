import {
  VISUAL_DESIGNER_LOADED,
  VISUAL_DESIGNER_SET_ERRORS,
  VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED,
  VISUAL_DESIGNER_SET_SETTINGS,
  VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES,
  VISUAL_DESIGNER_SET_VIEWDATA,
  VISUAL_DESIGNER_SET_CONTENT_BLOCKS_INFO,
} from './actions/general';
import { VISUAL_DESIGNER_UPDATE_SANA_TEXTS } from './actions/sanaText';
import { createReducer } from 'utils/redux';

const initialState = {
  initialized: false,
  errors: {},
  resourceTexts: {},
  settings: {},
};

export default createReducer(initialState, {
  [VISUAL_DESIGNER_LOADED]: onLoaded,
  [VISUAL_DESIGNER_SET_ERRORS]: onSetErrors,
  [VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED]: onReceiveResourceTexts,
  [VISUAL_DESIGNER_SET_SETTINGS]: onSetSettings,
  [VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES]: onSetCustomerSegmentTitles,
  [VISUAL_DESIGNER_SET_VIEWDATA]: onSetViewData,
  [VISUAL_DESIGNER_SET_CONTENT_BLOCKS_INFO]: onSetContentBlocksInfo,
  [VISUAL_DESIGNER_UPDATE_SANA_TEXTS]: onUpdateSanaTexts,
});

function onLoaded(state, action) {
  return {
    ...state,
    initialized: true,
    mode: action.payload.mode,
  };
}

function onSetErrors(state, action) {
  const { errors } = action.payload;
  return {
    ...state,
    errors,
  };
}

function onReceiveResourceTexts(state, action) {
  const { texts } = action.payload;
  return {
    ...state,
    resourceTexts: {
      ...state.resourceTexts,
      ...texts,
    },
  };
}

function onSetSettings(state, action) {
  const { settings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings,
    },
  };
}

function onSetCustomerSegmentTitles(state, action) {
  const { titles } = action.payload;
  return {
    ...state,
    customerSegmentTitles: { ...titles },
  };
}

function onSetViewData(state, action) {
  const { viewData } = action.payload;
  return {
    ...state,
    viewData,
  };
}

function onSetContentBlocksInfo(state, action) {
  const { contentBlocksInfo } = action.payload;
  return {
    ...state,
    contentBlocksInfo,
  };
}

function onUpdateSanaTexts(state, action) {
  return {
    ...state,
    editedSanaTexts: action.payload,
  };
}
