import { SalesAgreementStatus } from 'behavior/salesAgreements';

export const agreements = {
  items: Array.from(Array(5)).map((_, index) => ({
    id: index.toString(),
    status: SalesAgreementStatus.Active,
    url: '',
    effectiveDate: '-',
    expirationDate: '-',
  })),
  loadedLength: 5,
};
