import type { Epic, StoreDependencies } from 'behavior/types';
import type { UserAction } from './actions';
import type { Action } from 'redux';
import type { User, Viewer, Token } from './types';
import type { ImpersonationAction } from 'behavior/tools/impersonation';
import type { AppState } from 'behavior';
import { combineEpics, bufferBatchForLoading } from 'utils/rxjs';
import { ofType } from 'redux-observable';
import { of, Subject, NEVER, EMPTY, merge, concat, identity, timer, asapScheduler, Observable } from 'rxjs';
import {
  map, tap,
  switchMap, mergeMap, mergeMapTo,
  filter, distinct,
  pluck, catchError,
  first, switchMapTo,
  takeUntil, startWith,
  delayWhen, timeoutWith, observeOn, delay,
} from 'rxjs/operators';
import {
  USER_LOGIN, authenticated, USER_LOGOUT, USER_RELOAD,
  USER_ABILITIES_REQUESTED, userAbilitiesLoaded, USER_ABILITIES_LOADED,
  REPRESENT_CUSTOMER, USER_REGISTER, USER_CREATE_PROSPECT,
  logout, representCustomer, impersonationFailed,
} from './actions';
import { USER_ANON_EXPIRED, anonUserExpired } from './broadcastActions';
import { viewerChanged, navigateTo, NAVIGATION_REQUESTED } from 'behavior/events';
import { APP_INIT, APP_INIT_HYDRATE } from 'behavior/app';
import {
  loginMutation,
  createViewerQuery,
  loadAbilitiesQuery,
  representMutation,
  registrationMutation,
  createProspectMutation,
} from './queries';
import { ShopAccountType, UserType, AbilityState, AbilityTo } from './constants';
import { navigateToPrevious, reloadLocation, redirectTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { routesBuilder, RouteName } from 'routes';
import { registrationProcessed } from 'behavior/pages/registration';
import { createProspectProcessed } from 'behavior/pages/createProspect';
import { handleToken as _handleToken, createUserData, createMapLoginResult, convertAbilities } from './helpers';
import { unlockForm, FormName } from 'behavior/pages';
import { visibility$ } from 'utils/rxjs/eventsObservables';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { requestRoute } from 'behavior/route';
import { getBackToFromUrl } from 'behavior/pages/helpers';
import { ADMIN_IMPERSONATION_STOPPED, stop as stopAdminImpersonation } from 'behavior/tools/impersonation';
import { skipIfPreviewWithToast } from 'behavior/preview';
import { resetCaptcha } from 'behavior/captcha';
import { toasts } from 'behavior/toasts';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

const expirations$ = new Subject<Date | null>();

const authenticationEpic: Epic<UserAction> = (action$, state$, deps) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const mapLoginResult = createMapLoginResult(state$, deps, expirations$);

  return action$.pipe(
    ofType(USER_LOGIN),
    switchMap(action => deps.api.graphApi<UserLoginResult>(loginMutation, {
      input: action.payload.authData,
      keys: getAbilitiesKeys(state$),
    }, { retries: 0, useCookies: true }).pipe(
      mergeMap(result => mapLoginResult(action.payload.authData.email, result.profile.login, result.viewer)),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(unsetLoading)),
      takeUntil(locationChanged$),
      startWith(setLoading),
    )),
  );
};

const registrationEpic: Epic<UserAction> = (action$, state$, deps) => {
  const mapLoginResult = createMapLoginResult(state$, deps, expirations$);

  return action$.pipe(
    ofType(USER_REGISTER),
    switchMap(({ payload: { registrationData, updatedById } }) => deps.api.graphApi<RegistrationResult>(registrationMutation, {
      input: registrationData,
      loginInfo: {
        email: registrationData.email,
        password: registrationData.password,
        persistent: false,
      },
      keys: getAbilitiesKeys(state$),
    }, { retries: 0, useCookies: true }).pipe(
      mergeMap(result => {
        const {
          profile: { register: registrationResult, login: loginResult },
          viewer: viewerResult,
        } = result;

        registrationResult.isRegistered
          ? toasts.success('', { textKey: 'RegistrationForm_SuccessfullyRegistered' })
          : registrationResult.failureText && toasts.error(registrationResult.failureText, {});

        const unlockFormAction = registrationResult.isRegistered
          ? EMPTY
          : of(unlockForm(FormName.Registration));

        return concat(
          of(resetCaptcha(updatedById), registrationProcessed(registrationResult)),
          unlockFormAction,
          mapLoginResult(registrationData.email, loginResult, viewerResult),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(updatedById), registrationProcessed({ invalidInput: true }), unlockForm(FormName.Registration), unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(resetCaptcha(updatedById), registrationProcessed({ invalidInput: false }), unlockForm(FormName.Registration), unsetLoading)),
      startWith(setLoading),
    )),
  );
};

const createProspectEpic: Epic<UserAction> = (action$, state$, deps) => {
  return action$.pipe(
    ofType(USER_CREATE_PROSPECT),
    pluck('payload', 'prospectData'),
    switchMap(prospectData => deps.api.graphApi<CreateProspectResult>(createProspectMutation, {
      input: prospectData,
      keys: getAbilitiesKeys(state$),
    }, { retries: 0, useCookies: true }).pipe(
      pluck('profile', 'createProspect'),
      mergeMap(createProspectResult => {
        if (createProspectResult.isCreated && createProspectResult.contactId) {
          return of(representCustomer(createProspectResult.contactId, ShopAccountType.Contact));
        }

        return createProspectResult.isCreated
          ? of(createProspectProcessed(createProspectResult))
          : of(createProspectProcessed(createProspectResult), unlockForm(FormName.CreateProspect));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(createProspectProcessed({}), unlockForm(FormName.CreateProspect), unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(createProspectProcessed({}), unlockForm(FormName.CreateProspect), unsetLoading)),
      startWith(setLoading),
    )),
  );
};

const representationEpic: Epic<UserAction> = (action$, state$, dependencies) => action$.pipe(
  ofType(REPRESENT_CUSTOMER),
  skipIfPreviewWithToast(state$, dependencies),
  switchMap(action => dependencies.api.graphApi<ImpersonationResult>(representMutation, {
    id: action.payload.id,
    shopAccountType: action.payload.shopAccountType,
    keys: getAbilitiesKeys(state$),
  }, { retries: 0, useCookies: true }).pipe(
    filter(r => !!r.profile.impersonation),
    mergeMap(result => {
      const impersonationResult = result.profile.impersonation.represent;
      if (impersonationResult.failureText)
        return of(impersonationFailed());

      if (impersonationResult.token) {
        expirations$.next(impersonationResult.token.expiration);
        dependencies.api.setAuthToken(impersonationResult.token.value);

        const data = createUserData(result.viewer, true);
        const currentUser = state$.value.user as User;
        data.email = currentUser.email;
        data.type = currentUser.type;

        if (action.payload.redirectBack) {
          let navigateAction;
          if (action.payload.shopAccountType === ShopAccountType.Contact)
            navigateAction = navigateTo(routesBuilder.forHome());
          else {
            const backTo = getBackToFromUrl(dependencies.scope);
            navigateAction = backTo
              ? navigateTo(undefined, backTo.url)
              : navigateToPrevious([RouteName.Represent]);
          }

          return of(authenticated(data), viewerChanged(), navigateAction);
        }

        return of(authenticated(data), viewerChanged(), reloadLocation());
      }

      return action.payload.redirectBack ? of(navigateToPrevious()) : EMPTY;
    }),
    retryWithToast(action$, dependencies.logger, _ => EMPTY),
  )),
);

const checkAuthToken: Epic<UserAction> = (action$, state$, { api, logger, scope }) => {
  const isClient = scope === 'CLIENT';
  const actions$ = action$.pipe(ofType(APP_INIT, USER_RELOAD));
  let source$: Observable<Action | boolean> = actions$;

  if (api.authChanges$) {
    source$ = merge(
      actions$,
      api.authChanges$.pipe(
        switchMapTo(visibility$.pipe(
          first(identity),
        )),
      ),
    );
  }

  return source$.pipe(
    switchMap(action => api.graphApi<ViewerResult>(createViewerQuery(isClient, true), { keys: getAbilitiesKeys(state$) }, { useCookies: isClient }).pipe(
      isClient ? _handleToken(api, expirations$, false) : identity,
      mergeMap(({ viewer }) => {
        delete viewer.token;

        const currentUser = state$.value.user;
        const newUser = createUserData(viewer, isAuthenticated(viewer));

        if (typeof action !== 'boolean' && action.type === USER_RELOAD)
          return [authenticated(newUser), viewerChanged(), reloadLocation()];

        if (!currentUser.initialized || (currentUser.id === newUser.id && currentUser.customer?.id === newUser.customer?.id))
          return [authenticated(newUser)];

        return [authenticated(newUser), viewerChanged()];
      }),
      catchError(e => {
        logger.error(e);
        isClient && expirations$.next(null);
        api.setAuthToken(null);
        return NEVER;
      }),
    )),
  );
};

export function mapToActions(viewer: Viewer, currentUser: AppState['user']) {
  const newUser = createUserData(viewer, isAuthenticated(viewer));
  if (!currentUser.initialized || (currentUser.id === newUser.id && currentUser.customer?.id === newUser.customer?.id))
    return [authenticated(newUser)];

  return [authenticated(newUser), viewerChanged()];
}

export function handleToken(api: StoreDependencies['api']) {
  return _handleToken(api, expirations$);
}

const renewToken: Epic<UserAction> = (action$, state$, { api, logger, scope }) => {
  if (scope !== 'CLIENT')
    return EMPTY;

  const maxExpirationTimeout = 24 * 60 * 60 * 1000; // 1 day.

  return expirations$.pipe(
    observeOn(asapScheduler),
    switchMap(expiration => {
      if (!expiration)
        return EMPTY;

      const now = Date.now();
      const expirationDate = +new Date(expiration);
      const timeout = Math.min(expirationDate - now, maxExpirationTimeout);

      return timer(timeout / 2).pipe(
        delayWhen(_ => action$),
        mergeMap(_ => api.graphApi<ViewerResult>(createViewerQuery(true, true), { keys: getAbilitiesKeys(state$) }, { useCookies: true }).pipe(
          _handleToken(api, expirations$),
          map(({ viewer }) =>
            authenticated(createUserData(viewer, isAuthenticated(viewer))),
          ),
          catchError(e => {
            logger.error(e);
            api.setAuthToken(null);
            return of(logout());
          }),
        )),
        timeoutWith(timeout, of(logout())),
        takeUntil(merge(action$.pipe(ofType(USER_LOGIN, USER_LOGOUT)), api.authChanges$!)),
      );
    }),
  );
};

const loadAuthToken: Epic<Action> = (action$, state$, { api }) => action$.pipe(
  ofType(APP_INIT_HYDRATE),
  switchMap(_ => api.graphApi<ViewerResult>(createViewerQuery(true), undefined, { useCookies: true }).pipe(
    _handleToken(api, expirations$),
    filter(({ viewer }) => viewer.id !== state$.value.user.id),
    mergeMap(({ viewer }) => {
      return of(
        authenticated(createUserData(viewer, isAuthenticated(viewer))),
        viewerChanged(),
      );
    }),
  )),
);

const unauthenticationEpic: Epic<UserAction | ImpersonationAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  return action$.pipe(
    ofType(USER_LOGOUT, ADMIN_IMPERSONATION_STOPPED),
    switchMap(action => {
      if (state$.value.routing.routeData?.params?.previewToken != null)
        return of(navigateTo(routesBuilder.forHome()));

      const isLogoutAction = action.type === USER_LOGOUT
        || (action.type === ADMIN_IMPERSONATION_STOPPED && action.payload.isLogout);

      if (isLogoutAction && dependencies.toolsStorage.toolEnabled('Impersonate'))
        return of(stopAdminImpersonation(true));

      const requestOptions = {
        useCookies: true,
        authToken: isLogoutAction ? null : undefined,
      };

      return api.graphApi<ViewerResult>(createViewerQuery(true, true), { keys: getAbilitiesKeys(state$) }, requestOptions).pipe(
        _handleToken(api, expirations$),
        mergeMap(({ viewer }) => {
          const userData = createUserData(viewer, isAuthenticated(viewer));
          const authentication = state$.value.page.authentication;

          if (isLogoutAction && !state$.value.user.id)
            dependencies.broadcast.dispatch(anonUserExpired());

          if (authentication && isLogoutAction) {
            let redirectToLogin = authentication.required;
            if (!redirectToLogin && authentication.abilities) {
              redirectToLogin = authentication.abilities
                .some(ability => userData.abilities![ability] === AbilityState.Unauthorized);
            }

            if (redirectToLogin) {
              const loginRoute = routesBuilder.forLogin();
              return requestRoute(loginRoute, state$).pipe(
                mergeMap(path => merge(
                  of(redirectTo(path, 302, loginRoute)),
                  action$.pipe(
                    first(),
                    mergeMapTo(of(authenticated(userData), viewerChanged())),
                  ),
                )),
              );
            }
          }

          return merge(
            // Put this observable before emiting `authenticated` action, to subscribe to NAVIGATION_REQUESTED, since it can be dispatched synchronously.
            of(reloadLocation()).pipe(
              delay(10),
              takeUntil(action$.pipe(ofType(NAVIGATION_REQUESTED))),
            ),
            of(authenticated(userData), viewerChanged()),
          );
        }),
        catchError(e => {
          logger.error(e);
          return NEVER;
        }),
      );
    }),
  );
};

const mostUsedAbilities = [
  AbilityTo.ViewCatalog,
  AbilityTo.ViewPrices,
  AbilityTo.ViewStock,
  AbilityTo.ViewUnitOfMeasure,
  AbilityTo.ViewProductSuggestions,
  AbilityTo.ViewMyAccountPage,
  AbilityTo.OrderProducts,
  AbilityTo.CreateOrder,
  AbilityTo.CreateQuote,
  AbilityTo.CompareProducts,
  AbilityTo.SubscribeToNewsletter,
  AbilityTo.UseWishlist,
];

type UserAbilitiesEpic = Epic<UserAction> & {
  pushAbility: (key: AbilityTo, state: AbilityState) => void;
};

const userAbilitiesEpic: UserAbilitiesEpic = ((action$, state$, { api, completePendingActions$ }) => {
  const subject = new Subject<Action>();
  userAbilitiesEpic.pushAbility = (key: AbilityTo, state: AbilityState) => subject.next(userAbilitiesLoaded({ [key]: state }));

  return merge(
    subject,
    action$.pipe(
      ofType(USER_ABILITIES_REQUESTED),
      mergeMap(action => action.payload),
      distinct(undefined, action$.pipe(ofType(USER_ABILITIES_LOADED))),
      bufferBatchForLoading(completePendingActions$),
      map(keys => filterLoadedAbilities(state$, keys)),
      filter(keys => !!keys.length),
      mergeMap(keys => api.graphApi<AbilitiesResult>(loadAbilitiesQuery, { keys }).pipe(
        pluck('viewer', 'abilities'),
        map(abilities => userAbilitiesLoaded(convertAbilities(abilities))),
      )),
    ),
  );
}) as UserAbilitiesEpic;

function filterLoadedAbilities(state$: State, keys: AbilityTo[]) {
  const { initialized, expiredAbilities, abilities } = state$.value.user;
  return keys.filter(key => {
    if (abilities[key] && !expiredAbilities.includes(key))
      return false;

    if (!initialized && mostUsedAbilities.includes(key))
      return false;

    return true;
  });
}

const broadcastActionsEpic: Epic<UserAction> = (_action$, _state$, dependencies) => dependencies.broadcast.action$.pipe(ofType(USER_ANON_EXPIRED));

export default combineEpics(
  checkAuthToken,
  loadAuthToken,
  authenticationEpic,
  registrationEpic,
  representationEpic,
  unauthenticationEpic,
  userAbilitiesEpic,
  renewToken,
  createProspectEpic,
  broadcastActionsEpic,
) as Epic<Action>;

export function requestAbility(key: AbilityTo, state$: State, { api }: StoreDependencies) {
  const { abilities, expiredAbilities } = state$.value.user;

  const existingAbility = abilities[key];
  if (existingAbility && !expiredAbilities.includes(key))
    return of(existingAbility);

  return api.graphApi(loadAbilitiesQuery, { keys: [key] }).pipe(
    pluck('viewer', 'abilities', '0', 'state'),
    tap((state: AbilityState) => userAbilitiesEpic.pushAbility(key, state)),
  );
}

export function getAbilitiesKeys(state$: State) {
  const abilitiesFromState = Object.keys(state$.value.user.abilities);
  if (abilitiesFromState.length)
    return abilitiesFromState;

  return mostUsedAbilities;
}

function isAuthenticated(viewer: Viewer): boolean {
  return viewer.type === UserType.Registered || viewer.type === UserType.Admin;
}

type State = Parameters<Epic<UserAction>>[1];

type ViewerResult = {
  viewer: Viewer;
};

type UserLoginResult = ViewerResult & {
  profile: {
    login: { token: Token | null };
  };
};

type RegistrationResult = ViewerResult & {
  profile: {
    register: {
      failureText: string | null;
      isRegistered: boolean;
    };
    login: { token: Token | null };
  };
};

type CreateProspectResult = {
  profile: {
    createProspect: {
      isCreated: boolean;
      contactId: string | null;
    };
  };
};

type ImpersonationResult = ViewerResult & {
  profile: {
    impersonation: {
      represent: {
        failureText: string | null;
        token: Token | null;
      };
    };
  };
};

type AbilitiesResult = {
  viewer: Pick<Required<Viewer>, 'abilities'>;
};
