import { useSelector } from 'react-redux';
import { useIsVisualDesigner } from '../hooks';

export default useSanaTexts => (options, process) => {
  if (!useIsVisualDesigner())
    return useSanaTexts(options, process);

  const languageId = useSelector(state => state.localization.currentLanguage.id);
  const sanaTexts = useSelector(state => state.visualDesigner.editedSanaTexts);

  const loadedKeys = Object.keys(sanaTexts?.[languageId] || {});
  const getOptionKey = option => typeof option === 'string' ? option : option?.key;
  const isOptionLoaded = option => loadedKeys.includes(getOptionKey(option));

  const filteredOptions = options.filter(option => !isOptionLoaded(option));
  const { texts, loaded } = useSanaTexts(filteredOptions, process);
  let textIndex = 0;
  const result = options.map(option =>
    isOptionLoaded(option)
      ? sanaTexts[languageId][getOptionKey(option)]
      : texts[textIndex++]);

  return { texts: result, loaded };
};
