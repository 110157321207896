import { tap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { VisualDesignerMode } from 'behavior/visualDesigner';
import { createProductPageQuery } from './queries';
import productData from './stubData';
import { visualDesignerLoaded } from 'behavior/visualDesigner/actions/general';
import { processMedia, parseRelatedProducts } from 'behavior/pages/product';
import { parseQuery } from 'utils/url';
import { ProductSpecificationFilter } from 'behavior/products/product';

export default function handle(api, state) {
  const {
    routing: { navigatingTo: { location } },
    settings: { product: productSettings },
  } = state;
  const { itemId, languageId } = parseQuery(location.search);
  const decodedItemId = itemId && decodeURIComponent(itemId);

  if (decodedItemId) {
    languageId && api.setLanguage(languageId, false);
    const loadProductGroup = productSettings?.productGrouping.isEnabled;
    const variables = {
      productId: decodedItemId,
      specificationFilter: ProductSpecificationFilter.ForDetails,
    };

    return api.graphApi(createProductPageQuery({ loadProductGroup }), variables).pipe(
      map(({ pages: { product }, pageTemplates: { product: { preset: templateProductPreset } } }) => {
        const preset = product.preset || templateProductPreset;

        return {
          page: {
            ...product,
            preset,
            title: product.header ?? product.product.title,
            component: PageComponentNames.VisualDesigner,
          },
          action$: of(visualDesignerLoaded(VisualDesignerMode.ProductDetails)),
        };
      }),
      tap(processRelatedProductsAndMedia),
    );
  }
  else {
    return of({
      action$: of(visualDesignerLoaded(VisualDesignerMode.ProductDetails)),
      page: {
        product: productData,
        title: productData.title,
        component: PageComponentNames.VisualDesigner,
      },
    });
  }
}

function processRelatedProductsAndMedia(result) {
  const product = result.page.product;
  if (!product)
    return;

  product.relatedProductGroups = parseRelatedProducts(product.relatedProductGroups);
  product.media = processMedia(product.media);
}
