import { AlternativeItemGroupState, AlternativeItemsProduct } from './types';

export const ALTERNATIVE_ITEMS_PRODUCT_LIST_REQUESTED = 'ALTERNATIVE_ITEMS_PRODUCT_LIST_REQUESTED' as const;
export const requestAlternativeItemsProductList = (productIds: string[]) => ({
    type: ALTERNATIVE_ITEMS_PRODUCT_LIST_REQUESTED,
    payload: { productIds },
});
export type AlternativeItemsProductListRequestedAction = ReturnType<typeof requestAlternativeItemsProductList>;

export const ALTERNATIVE_ITEMS_PRODUCT_LIST_RECEIVED = 'ALTERNATIVE_ITEMS_PRODUCT_LIST_RECEIVED' as const;
export function alternativeItemsProductListReceived(products: AlternativeItemsProduct[]) {
    return {
        type: ALTERNATIVE_ITEMS_PRODUCT_LIST_RECEIVED,
        payload: { products },
    };
}
export type AlternativeItemsProductListReceivedAction = ReturnType<typeof alternativeItemsProductListReceived>;

export const ALTERNATIVE_ITEMS_SELECTED_GROUP_CHANGED = 'ALTERNATIVE_ITEMS_SELECTED_GROUP_CHANGED' as const;
export function alternativeItemsSelectedGroupChanged(alternativeItemGroupsState: AlternativeItemGroupState[]) {
    return {
        type: ALTERNATIVE_ITEMS_SELECTED_GROUP_CHANGED,
        payload: { alternativeItemGroupsState },
    };
}
export type alternativeItemsSelectedGroupChangedAction = ReturnType<typeof alternativeItemsSelectedGroupChanged>;

export type AlternativeItemsAction = ReturnType<
    | typeof alternativeItemsProductListReceived
    | typeof requestAlternativeItemsProductList
    | typeof alternativeItemsSelectedGroupChanged
>;