import type { Epic } from 'behavior/types';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { AlternativeItemsAction, alternativeItemsProductListReceived, ALTERNATIVE_ITEMS_PRODUCT_LIST_REQUESTED } from './actions';
import { productsQuery } from './queries';
import { VIEWER_CHANGED } from 'behavior/events';
import { AlternativeItemsProduct } from './types';

const epic: Epic<AlternativeItemsAction> = (action$, state$, { api }) => {

    const reset$ = action$.pipe(
        ofType(
            VIEWER_CHANGED,
        ),
    );

    const requestProducts$ = action$.pipe(
        ofType(ALTERNATIVE_ITEMS_PRODUCT_LIST_REQUESTED),
        switchMap(({ payload: { productIds } }) => {

            const variables = {
                options: {
                    ids: productIds,
                    page: {
                        index: 0,
                        size: productIds?.length,
                    },
                },
                ignoreGrouping: true,
            };

            return api.graphApi<GeneralProductsResponse>(productsQuery, variables, { retries: 0 }).pipe(
                pluck('catalog', 'products'),
                mergeMap(result => {
                    if (!result?.products.length)
                        return of(alternativeItemsProductListReceived([]));

                    return merge(
                        of(alternativeItemsProductListReceived(result.products)),
                    );
                }),
                takeUntil(reset$),
            );
        },
        ),
    );
    return merge(requestProducts$);
};

export default epic;

type GeneralProductsResponse = {
    catalog: {
        products: {
            products: AlternativeItemsProduct[];
        } | null;
    };
};