import { createReducer } from 'utils/redux';
import { AlternativeItemsAction, AlternativeItemsProductListReceivedAction, alternativeItemsSelectedGroupChangedAction, ALTERNATIVE_ITEMS_PRODUCT_LIST_RECEIVED, ALTERNATIVE_ITEMS_SELECTED_GROUP_CHANGED } from './actions';
import { AlternativeItemGroupState, AlternativeItemsProduct } from './types';

type State = {
    alternativeItemsProducts?: AlternativeItemsProduct[] | null;
    alternativeItemGroupsState?: AlternativeItemGroupState[] | null;
};

type Action = AlternativeItemsAction;

const initialState = {

};

export default createReducer<State, Action>(initialState, {
    [ALTERNATIVE_ITEMS_PRODUCT_LIST_RECEIVED]: onProductsReceived,
    [ALTERNATIVE_ITEMS_SELECTED_GROUP_CHANGED]: onAlternativeItemsSelectedGroupChanged,
});

function onProductsReceived(state: State, action: AlternativeItemsProductListReceivedAction): State {
    const { products: payloadProducts } = action.payload;
    return {
        ...state,
        alternativeItemsProducts: payloadProducts,
    };
}

function onAlternativeItemsSelectedGroupChanged(state: State, action: alternativeItemsSelectedGroupChangedAction): State {
    const { alternativeItemGroupsState } = action.payload;
    return {
        ...state,
        alternativeItemGroupsState,
    };
}