import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const ListPage = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./ListPage'));
const SalesAgreementDetails = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./Details'));

export default {
  [PageComponentNames.SalesAgreements]: page => (
    <ListPage desktop={page.content?.desktop} mobile={page.content?.mobile} />
  ),
};

/* eslint-disable react/no-multi-comp */
export function salesAgreementDetailsRenderer(page) {
  return <SalesAgreementDetails agreement={page.agreement} search={page.search} />;
}
