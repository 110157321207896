import styles from './ProductGroup.module.scss';
import { useState, useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ProductGroupContext from './ProductGroupContext';
import { UomSelector, useCanViewUom, QuantityTextBox, createQuantityModel } from 'components/primitives/product';
import { requestCalculatedProduct } from 'behavior/pages/productGroup';
import { addProducts } from 'behavior/basket';
import { addToBasket } from './AddToBasketButton';
import { getNumberDecimalsSeparator } from 'utils/format';
import { sanitizeId } from 'utils/helpers';

const QuantityBox = ({
  canOrderProducts,
  product,
}) => {
  const { quantities, updateQuantity, resetQuantities } = useContext(ProductGroupContext);
  const { uoms, id: productId, isOrderable } = product;

  const productQuantity = quantities.get(productId);
  const quantityValue = productQuantity && productQuantity.quantity && productQuantity.quantity.value;

  const canViewUom = useCanViewUom();
  const [uomId, updateUomId] = useState(product.uom && product.uom.id);
  const showUomSelector = canViewUom && uomId && uoms && uoms.length > 0;

  const dispatch = useDispatch();
  const { allowUOMSelection, culture } = useSelector(({ settings, localization }) => ({
    allowUOMSelection: settings.product?.allowUOMSelection,
    culture: localization.currentLanguage?.cultureName,
  }));

  const updateUom = useCallback(uomId => {
    updateUomId(uomId);
    updateQuantity(productId, uomId, null);
    dispatch(requestCalculatedProduct(productId, uomId));
  }, [productId, updateQuantity]);

  const quantityModel = useMemo(() => {
    const uom = uoms && uoms.find(u => u.id === uomId);

    return createQuantityModel(uom, true);
  }, [uomId]);

  const handleQuantityChange = useCallback(quantity => {
    updateQuantity(productId, uomId, quantity);
  }, [uomId]);

  const handleEnter = useCallback(({ which, key }) => {
    if (key === 'Enter' || which === 13)
      addToBasket(quantities, resetQuantities, lines => dispatch(addProducts(lines)));
  }, [quantities, resetQuantities]);

  const separator = getNumberDecimalsSeparator(culture);

  return (
    <>
      {isOrderable &&
        <div className={styles.quantityBox}>
          {canOrderProducts && (
            <div className={showUomSelector ? styles.quantityTextBox : styles.quantityTextBoxNoMargin}>
              {quantityModel && (
                <QuantityTextBox
                  id={`${sanitizeId(productId)}_qty`}
                  quantity={quantityModel}
                  value={quantityValue}
                  onChange={handleQuantityChange}
                  onKeyDown={handleEnter}
                  separator={separator}
                  allowResetOnStepDecrease
                />
              )}
            </div>
          )}
          {showUomSelector && (
            <div className={styles.uomSelector}>
              <UomSelector
                productId={productId}
                allowUOMSelection={allowUOMSelection}
                uomId={uomId}
                uoms={uoms}
                onUomChange={updateUom}
              />
            </div>
          )}
        </div>
      }
    </>
  );
};

QuantityBox.propTypes = {
  canOrderProducts: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    uoms: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default QuantityBox;