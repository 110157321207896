import styles from './Button.module.scss';
import { forwardRef, ReactNode, ReactElement, ButtonHTMLAttributes } from 'react';
import { joinClasses } from 'utils/helpers';
import ButtonContent from './ButtonContent';

const sizesStyleMap = {
  xs: styles.extraSmall,
  sm: styles.small,
  md: styles.medium,
  lg: styles.big,
} as const;
type Size = keyof typeof sizesStyleMap;

const themeStyleMap = {
  basic: styles.basic,
  regular: styles.regular,
  action: styles.action,
} as const;
type Theme = keyof typeof themeStyleMap;

export type Props = {
  size?: Size;
  theme?: Theme;
  icon?: ReactElement | false;
  iconOnLeft?: boolean;
  noContentWrapper?: boolean;
  children: ReactNode;
};

const Button = forwardRef<HTMLButtonElement, Props & ButtonHTMLAttributes<HTMLButtonElement>>(({
  className,
  size = 'md',
  theme = 'regular',
  icon,
  iconOnLeft,
  noContentWrapper,
  children,
  ...attributes
}, ref) => {
  const classNames = getButtonClassNames(size, theme, icon === false, iconOnLeft, className);

  return (
    <button className={classNames} {...attributes} ref={ref}>
      <ButtonContent icon={icon} noWrapper={noContentWrapper}>
        {children}
      </ButtonContent>
    </button>
  );
});

export default Button;

export function getButtonClassNames(size: Size, theme: Theme, noIcon: boolean | undefined, iconOnLeft: boolean | undefined, className: string | null | undefined) {
  return joinClasses(
    styles.base,
    sizesStyleMap[size],
    themeStyleMap[theme],
    noIcon && styles.noIcon,
    !noIcon && iconOnLeft && styles.iconOnLeft,
    className,
  );
}
