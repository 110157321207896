import createRenderer from './createProfileRenderer';
import { routesBuilder, RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';
import changePasswordRenderer from './changePassword';
import editProfileRenderer from './editProfile';
import { subAccountsRenderer, subAccountRenderer } from './subAccounts';
import orderAuthorizationsRenderer from './orderAuthorizations';
import prospectRenderer from './createProspect';
import orderTemplatesRenderer from './orderTemplates';
import { salesAgreementDetailsRenderer } from './salesAgreements';
import documentRenderer from './document';

const profileBackTextKeys = ['ButtonText_BackToProfile', 'AccountDashboard'];

export default {
  [PageComponentNames.ChangePassword]: createRenderer('ChangePassword', changePasswordRenderer, {
    linkKey: '/',
    backTo: {
      route: routesBuilder.forMyAccount(),
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.EditProfile]: createRenderer('YourDetails', editProfileRenderer, { linkKey: 'edit' }),
  [PageComponentNames.SubAccounts]: createRenderer('SubAccounts', subAccountsRenderer, { linkKey: 'subAccounts' }),
  [PageComponentNames.SubAccount]: createRenderer(null, subAccountRenderer, {
    linkKey: 'subAccounts',
    backTo: {
      route: routesBuilder.forSubAccounts(),
      texts: ['ButtonText_BackToSubAccounts', 'ManageSubAccounts'],
    },
  }),
  [PageComponentNames.CreateProspect]: createRenderer('CreateProspect_Header', prospectRenderer, { linkKey: 'createProspect' }),
  [PageComponentNames.OrderTemplates]: createRenderer('OrderTemplates_Header', orderTemplatesRenderer, { linkKey: 'templates' }),
  [PageComponentNames.SalesAgreement]: createRenderer(null, salesAgreementDetailsRenderer, {
    linkKey: 'agreements',
    backTo: {
      route: routesBuilder.forSalesAgreements(),
      texts: ['ButtonText_BackToSalesAgreements', 'MySalesAgreements'],
    },
  }),
  [PageComponentNames.Order]: createRenderer(null, documentRenderer, {
    linkKey: 'orders',
    backTo: {
      route: routesBuilder.forOrders(),
      texts: ['ButtonText_BackToOrders', 'MyOrders'],
      supportedRoutes: new Set([RouteName.MyAccount, RouteName.Orders, RouteName.OrderAuthorizations]),
    },
  }),
  [PageComponentNames.Quote]: createRenderer(null, documentRenderer, {
    linkKey: 'quotes',
    backTo: {
      route: routesBuilder.forQuotes(),
      texts: ['ButtonText_BackToQuotes', 'MyQuotes'],
      supportedRoutes: new Set([RouteName.Quotes]),
    },
  }),
  [PageComponentNames.Invoice]: createRenderer(null, documentRenderer, {
    linkKey: 'invoices',
    backTo: {
      route: routesBuilder.forInvoices(),
      texts: ['ButtonText_BackToInvoices', 'MyInvoices'],
      supportedRoutes: new Set([RouteName.Invoices]),
    },
  }),
  [PageComponentNames.ReturnOrder]: createRenderer(null, documentRenderer, {
    linkKey: 'returnOrders',
    backTo: {
      route: routesBuilder.forReturnOrders(),
      texts: ['ButtonText_BackToReturnOrders', 'MyReturnOrders'],
      supportedRoutes: new Set([RouteName.ReturnOrders]),
    },
  }),
  [PageComponentNames.CreditNote]: createRenderer(null, documentRenderer, {
    linkKey: 'creditNotes',
    backTo: {
      route: routesBuilder.forCreditNotes(),
      texts: ['ButtonText_BackToCreditNotes', 'MyCreditNotes'],
      supportedRoutes: new Set([RouteName.CreditNotes]),
    },
  }),
  [PageComponentNames.ReturnReceipt]: createRenderer(null, documentRenderer, {
    linkKey: 'returnReceipts',
    backTo: {
      route: routesBuilder.forReturnReceipts(),
      texts: ['ButtonText_BackToReturnReceipts', 'MyReturnReceipts'],
      supportedRoutes: new Set([RouteName.ReturnReceipts]),
    },
  }),
  [PageComponentNames.Shipment]: createRenderer(null, documentRenderer, {
    linkKey: 'shipments',
    backTo: {
      route: routesBuilder.forShipments(),
      texts: ['ButtonText_BackToShipments', 'MyShipments'],
      supportedRoutes: new Set([RouteName.Shipments]),
    },
  }),
  [PageComponentNames.OrderAuthorizations]: createRenderer('AuthorizationHistory', orderAuthorizationsRenderer, { linkKey: 'authorizations' }),
};
