import type { Filter, DocumentsResponse, DocumentsPage } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initContentSystemPage, loadContentSystemPageQuery, ContentSystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestDocuments } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';
import { getDocumentField, DocumentType } from 'behavior/documents';

export const size = 10;

export default function (documentType: DocumentType, componentName: PageComponentNames, query: string) {
  const handler: Handler<DocumentsRouteData, DocumentsPage> = ({ params, options: pageOptions }, state$, { api }) => {
    const filter = normalizeFilter(params?.filter);

    if (params?.previewToken) {
      const documentsFieldName = getDocumentField(documentType);

      return api.graphApi<DocumentsPreviewPageResponse>(loadContentSystemPageQuery(`page:${documentsFieldName}`)).pipe(
        map(({ pages: { page } }) => ({
          page: {
            component: componentName,
            documentType,
            docs: { totalCount: 0, items: [] },
            size,
            filter,
            ...initContentSystemPage(page),
          },
        })),
      );
    }

    const onlyItems = pageOptions?.onlyItems;
    const backTo = pageOptions?.backTo;
    const options = createOptions(params, filter, onlyItems);

    if (onlyItems)
      return of({
        action$: of(requestDocuments(options, documentType)),
        page: {
          ...state$.value.page as DocumentsPage,
          filter,
        },
      });

    return api.graphApi<DocumentsPageResponse>(query, { options }).pipe(
      map(({ pages: { page }, documents }) => ({
        page: {
          component: componentName,
          documentType,
          docs: documents.docs!.list,
          size,
          filter,
          backTo,
          ...initContentSystemPage(page),
        } as DocumentsPage,
      })),
    );
  };

  return handler;
}

export function createOptions<TFilter extends Filter>(params?: Params, filter?: TFilter, onlyItems?: boolean): { page: { index: number; size: number } } & TFilter {
  const index = params?.index || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  return { ...filter, page } as { page: { index: number; size: number } } & TFilter;
}

export function normalizeFilter<TFilter extends Filter>(filter?: TFilter): TFilter {
  if (filter?.orderDate)
    return filter;

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);

  return { ...filter, orderDate: { from: DateOnly.toISOString(from) } } as TFilter;
}

function getNumberOfDaysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

type Params = {
  filter: Filter;
  index?: number;
  previewToken?: string;
};

type DocumentsRouteData = {
  routeName:
  | RouteName.Orders
  | RouteName.Quotes
  | RouteName.Invoices
  | RouteName.ReturnOrders
  | RouteName.CreditNotes
  | RouteName.ReturnReceipts
  | RouteName.Shipments;
  params?: Params;
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type DocumentsPreviewPageResponse = {
  pages: {
    page: ContentSystemPageData;
  };
};

type DocumentsPageResponse = {
  pages: {
    page: ContentSystemPageData;
  };
} & DocumentsResponse;
